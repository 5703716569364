import Layout from '@layouts';
import * as React from 'react';
import { FC } from 'react';

type OurStoryPageProps = {};

const OurStoryPage: FC<OurStoryPageProps> = () => {
  return (
    <Layout url="/about-us/" title="About us">
      <h1 className="text-3xl lg:text-4xl font-emp text-center mt-20 lg:mt-30 mb-8">
        About us
      </h1>

      <p className="text-center lg:max-w-4xl mx-auto">
        Oilynx is your link to an ever transforming and accelerating energy
        market. We aim to provide a platform for all industry professionals
        seeking fast, reliable and valuable information to succeed with their
        trades and services. Make your interests public, explore new
        opportunities, discover new trade flows and business partners in your
        markets.
      </p>

      <p className="text-center lg:max-w-4xl mx-auto">
        Oilynx was formed from years of experience in the energy sector with the
        vision to fill the blind spots of today's trading environment. Together
        with you we will provide more visibility and information for all parties
        concerned, provide the basis for strong compliance, inform you of
        trading opportunities as they arise and maximise your market reach.
      </p>

      <p className="text-center lg:max-w-4xl mx-auto">
        Join Oilynx and be part of the transformation. It will be an honour to
        have you on board.
      </p>

      <h2 className="text-2xl font-emp text-center mt-10 mb-6">
        What we're about
      </h2>

      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-10 mb-20">
        <div className="lg:bg-background-avg rounded-md lg:px-10 lg:py-12">
          <h3 className="text-base text-center lg:text-left text-interaction-light font-emp">
            Fair & Transparent
          </h3>
          <p className="text-sm">
            We want to build a community of candid traders. While providing a
            high liquidity market place, we strive to create a more equitable
            environment for its participants.
          </p>
        </div>
        <div className="lg:bg-background-avg rounded-md lg:px-10 lg:py-12">
          <h3 className="text-base text-center lg:text-left text-interaction-light font-emp">
            Knowledgeable & Empowering
          </h3>
          <p className="text-sm">
            Our goal is to grow together. We share relevant data about trades in
            real time and educate our customers so that they can make the best
            informed decision regarding their affairs.
          </p>
        </div>
        <div className="lg:bg-background-avg rounded-md lg:px-10 lg:py-12">
          <h3 className="text-base text-center lg:text-left text-interaction-light font-emp">
            Friendly & Professional
          </h3>
          <p className="text-sm">
            We have a proactive approach when it comes to our professional
            relationships. We love our work and the industry we are in and aim
            to bring people together every day and everywhere.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default OurStoryPage;
